@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import 'styles/_custom-variables.scss';

.Player {
  &-Wrapper {
    touch-action: none;
    margin-top: 1rem !important;
    height: calc(100% - 1rem) !important;
    cursor: pointer;
    z-index: 3;
    overflow: hidden;

    &.overImage {
      cursor: url('../../../../public/assets/icons/zoom-in-out.png'), auto;
    }
    &.zoom {
      // cursor: zoom-out;
      cursor: url('../../../../public/assets/icons/zoom-out.png'), auto;
    }
  }

  .slick-slider:not(.webtoon) {
    div:not(.Player-fitscreen):not(.Player-fitbox):not(.Player-fitwidth) {
      height: calc(100vh - 4rem);
      @include media-breakpoint-up(sm) {
        height: calc(100vh - 1rem);
      }
      .Player-comments {
        height: initial;
        & div {
          height: initial;
        }
      }
    }
    .slick-slide {
      &:not(.slick-current) {
        transition: 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        opacity: 0;
      }
    }
  }

  .slick-list {
    min-height: 100%;
  }

  .slick-track {
    transition-duration: 500ms; // need change of slider speed
    will-change: transform;
    min-height: 100%;
  }

  &-Slider {
    &.webtoon {
      height: calc(100vh - 1rem) !important;
      // overflow: hidden;
    }
    // &:not(.webtoon):not(.fitcomments) {
    //   .Player-slide-wrapper {
    //     padding-bottom: 40px;
    //   }
    // }
  }

  &-slide {
    &.hide {
      height: 0;
    }
  }

  button.btn:not(.CommentForm-button):not(.CommentCard-action),
  a.btn:not(.CommentForm-button):not(.CommentCard-action) {
    display: block;
    font-family: $flowfont;
    //border: 2px solid white;
    padding-right: 3rem;
    padding-left: 3rem;
    margin-right: auto;
    margin-left: auto;
  }

  /* &-exit {
    font-family: $flowfont-regular;
    //font-style: normal;
    //font-weight: 500;
    font-size: 18px;
    //line-height: 21px;
    margin-bottom: 4px;
    z-index: 100;
  } */

  &-Info {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: var(--white);

    &-close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }

    &-Background {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      background: #333;
      opacity: 0.95;

      &.show {
        display: block;
      }
      &.m-show {
        @media (max-width: 991px) {
          display: block;
        }
      }

      &.hide {
        display: none !important;
        &.m-show {
          @media (max-width: 991px) {
            display: block !important;
          }
        }
      }
    }

    &-Wrapper {
      height: 100%;
      padding: 24px;
      z-index: 2;
      overflow: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      @media (min-width: 740px) {
        margin: 0;
        padding: 0;
      }
      .col {
        margin: 0;
        padding: 0;
      }
      img {
        width: 100%;
        height: auto;
        min-width: 0;
      }
    }

    &.show {
      display: block;
    }

    &.flex {
      display: flex;
    }

    &.hide {
      display: none;
    }
  }

  &-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    z-index: 9999;
    min-width: 344px;
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-Info-opening {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: var(--white);
    text-align: center;

    &-Wrapper {
      z-index: 10;
      margin: auto;
      max-width: 271px;
    }

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }

  &-Info-lastfile {
    font-family: $flowfont-thin;
    color: var(--white);
    text-align: center;
    max-height: 100vh;
    margin-top: 32px;
    padding-bottom: 64px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }

    &-Wrapper {
      margin: auto;
      font-size: 1rem;
      width: 340px;
      //max-width: 280px;
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
        width: 346px;
      }

      &.suggest {
        // padding-top: 1.5rem;
      }
    }

    &-title {
      font-family: $flowfont-medium;
      font-size: 24px;
    }

    &-gofirst {
      color: var(--white) !important;
      font-family: $flowfont-regular;
      margin-bottom: 16px;
      z-index: 100;
      padding-top: 0;
      padding-bottom: 0;
      border: none !important;
      &:hover {
        color: var(--white) !important;
      }
      @media (max-height: 390px) {
        display: none !important;
      }
    }

    &-joke {
      color: var(--white) !important;
      font-family: $flowfont-regular;
      margin-bottom: 16px;
      z-index: 100;
      padding-top: 0;
      padding-bottom: 0;
      border: none !important;
      &:hover {
        color: var(--white) !important;
      }
      @media (max-height: 390px) {
        display: none !important;
      }
    }

    &-artwork {
      display: flex;
      justify-content: center;
      max-height: 125px; // Safari
      img {
        width: 70px;
        margin-right: 11px;
        object-fit: contain;
      }
      &info {
        text-align: left;
        //font-weight: 500;
        font-size: 1rem;
        line-height: 1.4rem;
        font-family: $flowfont;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @include media-breakpoint-down(sm) {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
      &-nextflow {
        text-align: left;
        margin-top: 7px;
        display: inline;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @media (max-height: 390px) {
        display: none !important;
      }
    }

    &-favorite {
      font-family: $flowfont-thin;
      font-size: 16px;
    }

    &-lost {
      font-family: $flowfont-regular;
      //font-style: normal;
      //font-weight: 500;
      font-size: 18px;
      //line-height: 21px;
      margin-bottom: 8px;
      z-index: 100;
    }

    &-suggestions {
      margin-top: 50px;
      margin-left: 0;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 50px;
        max-width: 250px;
      }
      .Artwork-suggestions {
        color: var(--white);
      }
    }

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }

  &-Info-comments {
    position: fixed;
    overflow: hidden;
    display: flex;
    width: 456px !important;
    background: var(--distant);
    margin: 16px 0;
    padding: 24px 120px 24px 24px;
    cursor: inherit;
    z-index: 9;
    &-Background {
      z-index: 0;
    }
    @include media-breakpoint-down(md) {
      padding: 24px;
      top: -9999px;
      bottom: -9999px;
      left: 0 !important;
      margin: auto !important;
      height: 90% !important;
      width: initial !important;
      z-index: 2;
    }
  }

  &-comment-visitor {
    font-family: $flowfont-thin;
    color: var(--white);
    text-align: center;

    &-Wrapper {
      margin: auto;
      max-width: 271px;
    }

    &-title {
      font-family: $flowfont-medium;
      font-size: 24px;
    }
  }

  &-imgerror {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    z-index: 999999;
  }

  &-debugger {
    position: absolute;
    top: 5%;
    left: 3%;
    z-index: 999999;
    width: 300px;
    background-color: var(--redAdult);
    color: var(--white);
    border: 1px solid var(--white);
    padding: 4px 8px;
    font-size: 12px;
    li {
      list-style-type: none;
      word-wrap: break-word;
    }
  }
}
